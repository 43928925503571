import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { Link } from 'gatsby'

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
  },
  media: {
    height: 225,
  },
});

const Program = (props) => {
  const classes = useStyles();

  return (
    <Link to={props.link}>
    <Card className={classes.root} className={"program-card box-shadow "+ props.bgClass}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.imgSrc}
          title={props.imgTitle}
        />
        <CardContent>
          <div className="info-wrap">
            <Typography gutterBottom variant="h5" component="h6" className="card-main-title">
                {props.title}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" component="p" className="sub-title">
                {props.subtitle}
            </Typography>
          </div>
          <div size="small" color="primary">
          Learn More
        </div>
          
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
      </CardActions> */}
    </Card>
    </Link>
  );
}

export default Program;