import React, { useRef, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'

import Grid from '@material-ui/core/Grid';

import { gsap, TweenMax, Expo,TimelineMax } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Program from '../components/Program'
// import InstaFeed from '../components/InstaFeed'
import Messenger from '../components/Messenger'
import Testimonial from '../components/Testimonial'

import BackgroundSlider from 'react-background-slider'

import image1 from '../img/hero/heroslider1.jpg'
import image2 from '../img/hero/heroslider2.jpg'
import image3 from '../img/hero/heroslider3.jpg'
import image4 from '../img/hero/heroslider4.jpg'

import launch from '../img/program/launch.jpg'
import ignite from '../img/program/Ignite.jpg'
import propel from '../img/program/propel.jpg'
import ascend from '../img/program/ascend.jpg'

import kids from '../img/curriculum/propel.jpg'
import adults from '../img/adults/adults_loading.jpg'

import ContactPopup from '../components/ContactPopup';
import Banner from '../components/Banner';


gsap.registerPlugin(ScrollTrigger);


export const IndexPageTemplate = ({handleProgramTypeSelector, isProgramTypeSelected, urlProgramSelected}) => (

  <>
  {isProgramTypeSelected == false ? (
  <div className="program-selector">
          <Grid container>
            <Grid item xs={12} sm={6} md={6} className="program-type-kids" >
            <img src={kids} className="program-type-img" alt="kids program" />
              <div className='text-center program-type-text'>
                <h4>Programmes for</h4>
                <h2>Kids</h2>
                <Link to="/" onClick={() => handleProgramTypeSelector(true) }>
                  <button variant="contained" className="btn btn-outine bg-white white mt-2">
                      Explore
                  </button>
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="program-type-adults">
              <img src={adults} className="program-type-img" alt="adults program" />
              <div className='text-center program-type-text'>
                <h4>Programmes for</h4>
                <h2>Adults</h2>
                <Link to="/adults" onClick={() => handleProgramTypeSelector(true)}>
                  <button variant="contained" className="btn btn-outine bg-white white mt-2">
                      Explore
                  </button>
                </Link>
              </div>
              
            </Grid>
        </Grid>

    </div>
) : (
  <div>
    <div className="overlay">
      <h1 className="loading-title">Sesquipedalian {}</h1>
      <span className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen">Theatre led learning</span>
    </div>

    <div className="hero-background">
    <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h1 className="hero-title" >
          Sesquipedalian
        </h1>
        <h3
          className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen hero-subtitle" >
          Theatre led learning
        </h3>

        <Link to="/contact">
          <button variant="contained" color="primary" className="btn btn-primary btm-sm-full-width">
            Join Now
          </button>
        </Link>
      </div>
      <BackgroundSlider
      images={[image1, image2, image3, image4]}
      duration={4} transition={2} />
    </div>

      <Messenger />
      
      <div className="mb-none">
        <ContactPopup />
      </div>
      
    <section className="section-intro">
      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} >
          {/* <h3 className="has-text-weight-semibold is-size-2 is-size-4-mobile is-size-2-tablet is-size-1-widescreen intro-heading">
            {heading}
          </h3> */}
          <h1 className="main-title">"Learning builds <span className="mb-ml-1">character</span></h1>
          <h1 className="main-title ml-5 mt--1 mb-text-right mb-mt-0-5 mb-mb-1">Theatre builds performers"</h1>
        </Grid>
        <Grid item xs={12} sm={12} md={3} >
        </Grid>
        <Grid item xs={12} sm={12} md={9} >
          <p className="page-description">Sesquipedalian Theatre-led Learning is a contemporary academic curriculum that brings the principles of the performing arts to a comprehensive language and personality development programme for children between the ages of 5 and 16.</p>
          <p className="page-description">With tools and techniques from the stage to enhance the experience, students can develop their academic, leadership, cognitive and social skills to reach their potential as high performers.</p>
          
          <div className="flex justify-center">
          <Link to="/curriculum">
            <button variant="contained" color="primary" className="btn btn-link">
              Learn More
            </button>
          </Link>

          </div>
        </Grid>
      </Grid>
    </section>

    <section className="section-program-home">
      <Grid container 
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}

      >
        <Grid item xs={12} sm={12} md={9} className="mb-pb-0 mb-title-wrap">
            <div className="gsap-curriculum-title">
              <h3 className="has-text-weight-semibold is-size-2 is-size-2-mobile is-size-2-tablet is-size-2-widescreen section-title-mb">
                Curriculum
              </h3>
            </div>

            <div className="none mb-title-line"></div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} className="mb-none">
          {/* <Link to="/contact">
            <button variant="contained" color="primary" className="btn btn-primary float-right btm-sm-full-width gsap-trial-button">
              Trial Lesson
            </button>
          </Link> */}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <div>
            <Program imgSrc={launch}
              title={"Launch"}
              subtitle={"Ages 4-7"}
              info={""}
              link={"/curriculum#launch"}
              imgTitle={"Launch"}
              bgClass={"program-card-launch"}
            />
          </div>

        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div>
            <Program imgSrc={ignite}
            title={"Ignite"}
            subtitle={"Ages 8-11"}
            info={""}
            link={"/curriculum#ignite"}
            imgTitle={"Ignite"}
            bgClass={"program-card-ignite"}
          />
          </div>
          
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <div>
          <Program imgSrc={propel}
            title={"Propel"}
            subtitle={"Ages 12-15"}
            info={""}
            link={"/curriculum#propel"}
            imgTitle={"Propel"}
            bgClass={"program-card-propel"}
          />
           </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
        <div>
          <Program imgSrc={ascend}
            title={"Ascend"}
            subtitle={"Ages 15 - 18"}
            info={""}
            link={"/curriculum#ascend"}
            imgTitle={"Ascend"}
            bgClass={"program-card-ascend"}
          />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={3} className="none mb-block">
          {/* <Link to="/contact">
            <button variant="contained" color="primary" className="btn btn-primary float-right btm-sm-full-width gsap-trial-button-mb">
              Trial Lesson
            </button>
          </Link> */}
        </Grid>
      </Grid>
    </section>
      
    {/* <section className="section-insta">
      <InstaFeed />
    </section> */}

    <div>
      <Banner />
    </div>

    <div>
      <Testimonial />
    </div>
    </div>
)}

    
  </>

)


IndexPageTemplate.propTypes = {
  
}

const IndexPage = () => {

  const [isProgramTypeSelected, handleProgramTypeSelector] = useState(false);

  let urlProgramSelected = false;

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
    
      const urlProgramSelected = urlParams.get('isProgramSelected')

      if(urlProgramSelected == true){
        handleProgramTypeSelector(true);
    }
    }

  },[isProgramTypeSelected])

 
  let app = useRef(null);

  useEffect(() => {

    TweenMax.to(app, 0, { css: { visibility: 'visible' } });

    TweenMax.to(".overlay h1", 5, {
      opacity: 0,
      y: -60,
      ease: Expo.easeInOut
    })

    TweenMax.to(".overlay span", 6, {
      delay: .6,
      opacity: 0,
      y: -60,
      ease: Expo.easeInOut
    })

    TweenMax.to(".overlay", 6, {
      delay: 2,
      top: "-120%",
      ease: Expo.easeInOut
    })

    var msgtl = new TimelineMax({
      delay:6
    });
    msgtl.add("begin");
    msgtl.to(".fb-customerchat",0.5,{opacity:1});

    // Animate Intro
    gsap.from(".gsap-main-1",3, {
      scrollTrigger: {
        trigger: '.gsap-main-1',
        start:'top center'
      },
      x:-50,
      opacity: 0,
    })

    gsap.from(".gsap-main-2",3, {
      scrollTrigger: {
        trigger: '.gsap-main-2',
        start:'top center'
      },
      x:50,
      opacity: 0
    })
    
    gsap.from(".gsap-intro-desc",3, {
      scrollTrigger: {
        trigger: '.gsap-intro-desc',
        start:'top center'
      },
      opacity: 0
    })
    
    //Animate Programs
    gsap.from(".gsap-curriculum-title",2, {
      scrollTrigger: {
        trigger: '.gsap-curriculum-title',
        start:'top center'
      },
      x:-100,
      opacity: 0
    })

    // gsap.from(".gsap-trial-button",2, {
    //   scrollTrigger: {
    //     trigger: '.gsap-trial-button',
    //     start:'top center'
    //   },
    //   x:100,
    //   opacity: 0
    // })

    gsap.from(".gsap-curriculum-line",2, {
      scrollTrigger: {
        trigger: '.gsap-curriculum-line',
        start:'top center'
      },
      x:100,
      opacity: 0
    })

    // gsap.from(".gsap-trial-button-mb",2, {
    //   scrollTrigger: {
    //     trigger: '.gsap-trial-button-mb',
    //     start:'top center'
    //   },
    //   opacity: 0
    // })

    gsap.from(".program1",2, {
      scrollTrigger: {
        trigger: '.program1',
        start:'top center'
      },
      x:-60,
      opacity: 0
    })

    gsap.from(".program2",2, {
      scrollTrigger: {
        trigger: '.program2',
        start:'top center'
      },
      x:-30,
      opacity: 0
    })

    gsap.from(".program3",2, {
      scrollTrigger: {
        trigger: '.program3',
        start:'top center'
      },
      x:30,
      opacity: 0
    })

    gsap.from(".program4",2, {
      scrollTrigger: {
        trigger: '.program4',
        start:'top center'
      },
      x:60,
      opacity: 0
    })

    //Animate Testinomials
    gsap.from(".gsap-testinomials",2, {
      scrollTrigger: {
        trigger: '.gsap-testinomials',
        start:'top center'
      },
      opacity: 0
    })

  });

  return (
      <Layout isProgramTypeSelected={isProgramTypeSelected} page="index">
        <div className="index-page" ref={el => app = el}>
          <IndexPageTemplate 
            handleProgramTypeSelector={handleProgramTypeSelector}
            isProgramTypeSelected={isProgramTypeSelected}
            urlProgramSelected={urlProgramSelected}
          />
        </div>
      </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage
