import React from 'react'
import Slider from "react-slick";

import Grid from '@material-ui/core/Grid';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from '../img/testinomials/Amirah.jpg';
import img2 from '../img/testinomials/Aaron.jpg';
import img3 from '../img/testinomials/Kavindra.jpg';
import img4 from '../img/testinomials/Dilenyas.jpg';
import quote1 from '../img/testinomials/quote1.png';
import quote2 from '../img/testinomials/quote2.png';

export default class Testimonial extends React.Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
      pauseOnHover: true,
      fade: true,
      arrows:false 
    };
    return (
    <>
      <div>
      <Grid
          container
          direction="row"
          spacing={4}
          className="mb-none quote-wrap"
        >
          <Grid item xs={12} sm={12} md={2}>
            <img src={quote1} alt="quote image" className="quote-img"/>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <img src={quote2} alt="quote image" className="quote-img"/>
          </Grid>
        </Grid>
      </div>

      <div className="testimonial-wrap">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
          className="mb-testinomials-parent-wrap"
        >
          <Grid item xs={12} sm={12} md={3}>
            <div className="testnomial-title mb-mt-2">
              <h3 className="has-text-weight-semibold is-size-3 is-size-5-mobile is-size-3-tablet is-size-2-widescreen section-title-mb">
                What <span className="block mb-inline">Parents</span> Say
                  </h3>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={9}>
            <div>
              <Slider {...settings}>
                <div className="testimonials-item mb-mt-1">
                  <p className="description">" Students gain confidence and build their personality in a fun environment.
                  They learn teamwork and respect for each other. Personal attention given by
                  the teacher has helped many kids overcome shyness and stage fright."
                    </p>
                  <p className="author-name">Dulani Jayaratne</p>
                  <p className="author-info">Senior Vice President, at Citi bank NA, Srilanka</p>
                </div>

                <div className="testimonials-item">
                  <p className="description">" I deeply appreciate the energy, time, dedication and effort invested by Miss Amanda Paiva to transform my
son into an individual that demonstrates initiative and positivity. My son Ahmad has been given the
opportunity to be a part of outstanding stage performances. "
                    </p>
                  <p className="author-name">Parveen Mubarak</p>
                  <p className="author-info"></p>
                </div>

                <div className="testimonials-item">
                  <p className="description">" My son’s ability to work with others has been immensely enhanced and
                  his progress in public performance has developed tremendously,
                  he has learned to love theatre and music "
                    </p>
                  <p className="author-name">Sugath and Roshini Caldera</p>
                  <p className="author-info">Attorneys –at –Law</p>
                </div>
              </Slider>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
          className="testinomials-students mb-5 mb-testinomials-student-wrap"
        >
          <Grid item xs={12} sm={12} md={9}>
            <div>
              <Slider {...settings}>
                <div className="testimonials-item">
                  <p className="description mt-3 mb-mt-1">" I learn that teamwork is important, I have fun and I am now a performer! "
                  </p>
                  <img src={img1} alt="Amirah" className="testimonial-img" />
                  <p className="author-name"> Amirah shakir, <span className="author-info">7</span></p>
                </div>

                <div className="testimonials-item">
                  <p className="description mt-3 mb-mt-1">" I feel confident to participate in discussions and events! "
                  </p>
                  <img src={img2} alt="Amirah" className="testimonial-img" />
                  <p className="author-name">Aron Rammogan, <span className="author-info">11</span></p>
                </div>

                <div className="testimonials-item">
                  <p className="description mt-3 mb-mt-1">" I’ve developed my interpersonal skills "
                  </p>
                  <img src={img3} alt="Amirah" className="testimonial-img" />
                  <p className="author-name">Kavindrra Samarasekera, <span className="author-info">12</span></p>
                </div>

                <div className="testimonials-item">
                  <p className="description">" When I was younger the thought of performing in front of hundreds of people 
                  with bright lights flashing at me absolutely terrified me. This 
                  programme helped me vanquish my fears and boost my very low self-esteem 
                  and made me a confident, stronger individual, a better person overall "
                  </p>
                  <img src={img4} alt="Amirah" className="testimonial-img" />
                  <p className="author-name">Dilenya Ellawala, <span className="author-info">past student</span></p>
                </div>
              </Slider>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <div className="testnomial-title bg-accent mb-mt-2">
              <h3 className="has-text-weight-semibold is-size-3 is-size-5-mobile is-size-3-tablet is-size-2-widescreen section-title-mb text-right">
                What<span className="block mb-inline"> Students </span> Say
              </h3>
            </div>
          </Grid>

        </Grid>
      </div>
      </>
    )
  }
}
