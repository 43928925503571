import React from "react";
import { TimelineMax } from "gsap";
import { Link } from 'gatsby'

class ContactPopup extends React.Component {
  state ={
    hidePopup: false
  }

  componentDidMount() {
    var poptl = new TimelineMax({
      delay:10
    });
    poptl.add("begin");
    poptl.to(".contact-popup",0.5,{opacity:1});
  }

  handleHidePopup = () =>{
    this.setState({
      hidePopup :true
    })
  }

  render() {
    return (
      <div className={this.state.hidePopup? ("contact-popup-hidden"): ("contact-popup")}>
          <h1>Hey, You can reach us at</h1>
          <h2><a href="tel:0777768769">0777 768 769</a></h2>
          <h3>(Call/WhatsApp)</h3>
          <Link to="/curriculum#weeklySchedule">
            <button variant="contained" color="primary" className="btn btn-link bg-secondary white">
                Check Weekly Schedule
            </button>
          </Link>
          <p onClick={this.handleHidePopup}>Skip</p>
      </div>
    );
  }
} 

export default ContactPopup;