import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap, TweenMax, Expo,TimelineMax } from "gsap";

import { Link } from 'gatsby'

gsap.registerPlugin(ScrollTrigger);

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
  },
  media: {
    height: 225,
  },
});

const Banner = (props) => {
  const classes = useStyles();
  
  // useEffect(() => {

  //   gsap.from(".banner-wrap",3, {
  //     scrollTrigger: {
  //       trigger: '.banner-wrap',
  //       start:'top bottom'
  //     },
  //     y:200,
  //     opacity: 0,
  //     ease: Expo.easeInOut
  //   })

  //   gsap.from(".banner-sub-title",3, {
  //     scrollTrigger: {
  //       trigger: '.banner-sub-title',
  //       start:'top bottom'
  //     },
  //     delay: 5,
  //     opacity: 0,
  //   })

  //   gsap.from(".banner-btn",2, {
  //     scrollTrigger: {
  //       trigger: '.banner-btn',
  //       start:'top bottom'
  //     },
  //     opacity: 0,
  //   })

  //   gsap.from(".banner-footer-left",2, {
  //     scrollTrigger: {
  //       trigger: '.banner-footer-left',
  //       start:'top center'
  //     },
  //     x:-100,
  //     opacity: 0,
  //   })

  //   gsap.from(".banner-footer-right",2, {
  //     scrollTrigger: {
  //       trigger: '.banner-footer-right',
  //       start:'top center'
  //     },
  //     x:100,
  //     opacity: 0,
  //   })

  // });

  return (
    <>
    <div className="banner-wrap mt-5 mb-mt-1">
        <h1 className="banner-title section-title-mb">Do you want your child to communicate with confidence, think creatively and be ready to take on the challenges of the 21st century?</h1>
        <h2 className="banner-sub-title">Find out how our classes can help by booking a trial lesson!</h2>
        <Link to="/contact" className="flex justify-center banner-btn">
            <button variant="contained" color="primary" className="btn btn-accent mt-2">
              Trial Lesson
            </button>
        </Link>
    </div>
            <div className="banner-footer">
            <div className="banner-footer-left">
              <h3>Contact Us On</h3>
              <h1>0777 768 769</h1>
              
              <h3 className="mb-mt-1">Email</h3>
              <h1 className="banner-footer-email-mb">visitus@sesquipedalian.lk</h1>
            </div>
            <div className="banner-footer-right">
              <h3>We currently conduct our classes at</h3>
              <h1>Oneness Centre,</h1>
              <h1>3A Col TG Jayawardena Mawatha,</h1>
              <h1>Colombo 00300</h1>
            </div>
        </div>
        </>
  );
}

export default Banner;
